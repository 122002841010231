"use client";
import { ConfigProvider, theme } from "antd";
import { ReactNode } from "react";

const ThemeProvider = ({ children }: { children: ReactNode }) => {
	return (
		<ConfigProvider
			theme={{
				algorithm: theme.darkAlgorithm,
				components: {
					Layout: {
						siderBg: "#121212",
					},
					Menu: {
						darkItemBg: "#121212",
						darkSubMenuItemBg: "#1E1E1E",
					},
				},
			}}
		>
			{children}
		</ConfigProvider>
	);
};
export default ThemeProvider;
